input.cost_price-editable {
    width: 50px !important;
}

.componentTabList {
    margin-top: 7rem;
    background: white;
    padding: 0 10rem;
    box-shadow: 0px 3px 6px #0000000A;
}

.componentTabList .nav-item {
    padding: 0.25rem 3rem;
    padding-top: 1rem;
}

.overridden-field input {
    border-color: green;
}

span.pending-removals {
    color: red;
    opacity: 0.7;
}