.search-results-modal {
    margin-top: 7.5rem;
    max-width: 80%;
}

@media (max-width: 1500px) {
  .search-results-modal {
    max-width: 98%;
  }
}
