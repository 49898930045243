#doors-list .index-table {
    height: calc(90vh - 16rem);
}

#doors-list tr.sub-row {
    background-color: #f3f3f3;
}

#doors-list tr.sub-row:hover {
    background-color: #f9f9f9 !important;
}

tr.sub-row .position-editable {
    display: none;
}

tr.group-header {
    background-color: #FF4D4D25;
    white-space: nowrap;
}

tr.group-header:hover {
    background-color: #FF4D4D30 !important;
}

tr.group-header td {
    padding: 13px;
    color: #FF4D4D;
    font-weight: 500;
}

input.reference-editable {
    min-width: 100px;
}

#new-door-dropmenu .dropdown-item {
    color: #616161;
}

@media (max-width: 769px) {
    #doors-list .content-main {
        margin: 0px;
    }

    .table thead th {
        top: 0;
    }

    #new-door-dropmenu .dropdown-menu {
        left: 0rem;
    }
}

.duplicate-via-serial-modal {
    margin-top: 2rem;
    max-width: 95%;
}

.duplicate-via-search-modal .table thead th {
}

.duplicate-via-search-modal .search-header-row {
    position: sticky;
    top: 2rem;
    z-index: 2000;
    background: #fff;
    max-width: 100%;
    margin: auto;
}

.duplicate-via-serial-modal .dropdownInput-wrapper {
    z-index: 1100;
    position: sticky;
    top: 0;
}

.duplicate-via-search-modal {
    margin-top: 2rem;
    max-width: 98%;
}

.duplicate-btn:hover {
    cursor: pointer;
}

@media (max-width: 1500px) {
    .duplicate-via-serial-modal {
        max-width: 98%;
    }
}
