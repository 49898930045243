.editable-field {
    display: block;
    min-width: 50px;
    min-height: 20px;
}

.blank-override-label {
    min-width: 50px;
    min-height: 20px;
}

.editable-field-input {
  width: 50%;
  height: 25px;
  border: 1px solid grey;
  opacity: 0.9;
  padding-left: 3px;
  margin: 0;
  -moz-appearance: textfield;
  -webkit-appearance: none;
}

#calculator_override\.skins_part_code {
    width: 140px;
}

.editable-field.edited {
  color: green;
  font-weight: 500;
}

.editable-field span.material-icons-outlined {
  color: red;
  cursor: pointer;
}

#sliders-list {
  list-style: none;
}

#sliders-list li {
  color: #9c9c9c;
  text-decoration: underline;
}

#parts-table tr.overridable {
  background-color: #fafcd7;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}
input[type="number"] {
   -moz-appearance: textfield;
}
